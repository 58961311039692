$desktop_small: 1024px;
$desktop_medium: 1299px;
$tablet: 900px;
$mobile: 600px;


.Demorequest {
    .qtfy_hero_container {
        background: url(../../assets/images/public/hero_bg.png), #1CADD7;
        background-repeat: no-repeat;
        background-size: cover;
        height: 18.625rem;
        width: 100%;
        margin: 0;

        @media screen and (max-width: $mobile) {
            height: auto;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .qtfy_hero_content_panel {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 2rem;

            @media screen and (max-width: $mobile) {
                height: auto;
                text-align: center;
            }

            .hero_title {
                color: #ffffff;
                font-size: 2rem;
                font-weight: 500;

                @media screen and (max-width: $mobile) {
                    margin: 2rem auto;
                }
            }

            .demoReg_hero_panel {
                line-height: 1.3;

                .demoreg_title {
                    font-size: 3rem;
                    color: #ffffff;
                    font-weight: 500;
                }

                .demoreg_title_content {
                    font-size: 3rem;
                    color: #ffffff;
                    font-weight: 300;
                }
            }
        }

        .qtfy_hero_right {
            position: relative;
            height: 100%;

            @media screen and (max-width: $mobile) {
                height: auto;
                text-align: center;
            }

            img {
                position: absolute;
                right: 0;
                bottom: 1px;

                @media screen and (max-width: $mobile) {
                    position: relative;
                    margin: auto;
                    width: 20rem;
                }
            }
        }
    }

    .qtfy_public_body {
        background-color: #F7F7F7;
    }

    .regdemo_imagePanel {
        width: 500px;
    }

    .demoReg_form_panel {
        box-shadow: 0px 0px 20px #0000000F;
        border: 1px solid #EBEBEB;
        border-radius: 4px;
        background-color: #F7F7F7;
        padding: 2.8rem;

        @media screen and (max-width: $mobile) {
            padding: 1.5rem;
        }

        .demoReg_form_panel_title {
            font-size: 1.125rem;
            color: #333333;
            font-weight: 300;
        }
    }

    .qtfy_pg_title {
        margin: 0;
        color: #828282;
        font-size: 2rem;
        font-weight: 300;
    }

    .qtfy_paragraph_title {
        color: #333333;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.5;
    }

    .qtfy_paragraph {
        color: #333333;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.5;
    }

    label.form-label {
        color: #333333;
    }
}