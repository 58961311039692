.op_input_block {
    position: relative;
    margin: 10px 0;

    .passwordInput {
        position: relative;

        .icon-view {
            border-radius: 0px;
            background: transparent;
            border-right: 0;
            border-color: #012970;
            position: absolute;
            top: 50%;
            z-index: 999;
            display: inline-flex;
            border: 0;
            transform: translateY(-50%);
            right: 20px;
            cursor: pointer;

            i {
                color: #828282;
            }
        }
    }

    .form-check-input:checked {
        background-color: #0BADD7;
        border-color: #0BADD7;
    }

    .form-check-input[type=checkbox] {
        border-radius: 4px;
    }

    label.form-label {
        font-size: .875rem;
        font-weight: 400;
    }

    .input_error_msg {
        position: absolute;
        font-size: 10px;
        color: #FC0D1B;
        font-weight: 500;
        margin-top: 0px;
    }

    input.form-control {
        font-size: 0.875rem;
        font-weight: 400;
        border: 1px solid #b6b6b6;
        height: 42px;
        padding: 10px 20px;
        border-radius: 4px;

        &:focus {
            border: 1px solid #b6b6b6;
            background-color: #FFFFFF !important;
        }

        &.invalid {
            border: 1px solid #FF0000 !important;
            background-color: #ff00001a !important;
        }

    }

    input.form-select {
        box-shadow: none !important;
        padding: 10px 20px;
        height: 42px;
        font-size: .875rem;
        font-weight: 400;
        border: 1px solid #b6b6b6;

        &:focus {
            border: 1px solid #b6b6b6;
            background-color: #FFFFFF !important;
        }

        &.invalid {
            border: 1px solid #FF0000 !important;
        }
    }
}

textarea.form-control {
    &.invalid {
        border: 1px solid #FF0000 !important;
    }
}

// jmx Upload field
.filenameWrapper {
    display: block;
    width: 100%;
    padding: 10px 20px;
    height: 42px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #b6b6b6;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.invalid {
        border: 1px solid #FF0000 !important;
        background-color: #ff00001a !important;
    }
}

.fileUploadWrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    .customfileinput {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 9999;
    }
}

.dragWrapper {
    border: 1px solid #B6B6B6;
    border-radius: 4px;
}

.csvDragContainer {
    background: #EBEBEB;
    padding: 1rem;
    min-height: 100px;
    width: 100%;
    position: relative;

    .buttonContainer {
        width: 400px;
        height: 70px;
        border-radius: 4px;
        margin: auto;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;

        .addflie_panel {
            &_content {
                font-size: 14px;
                font-weight: 600;
                color: #363636;
            }

        }

        .icon {
            svg {
                width: 35px;
                height: 35px;
            }
        }

        &_content {
            font-size: 13px;
            font-weight: 400;
        }
    }
}

.UploadedFileListPanel {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .UploadedFile {
        .file_box {
            height: 71px;
            width: 71px;
            position: relative;

            .btn_file_close {
                background: #363636;
                padding: 3px;
                color: #ffffff;
                width: 16px;
                height: 16px;
                font-size: 9px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                position: absolute;
                top: 0px;
                right: 24px;
                font-weight: bold;
            }
        }
    }
}

//Wizard search Bar
.search-bar {
    --size: 42px;
    border: 1px solid $mainblue;
    display: flex;
    border-radius: 50px;
    height: var(--size);
    width: var(--size);
    padding: 1.5px;
    position: relative;
    overflow: hidden;
    transition: width 500ms cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &__input {
        flex-grow: 1;
        font-size: .8rem;
        font-weight: 500;
        border: 0;
        padding: 0 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc(100% - var(--size));
        line-height: calc (var(--size) - 3px);
        opacity: 0;

        &:focus {
            outline: 0;
        }
    }

    &__submit {
        font-size: .8rem;
        cursor: pointer;
        border-radius: 50%;
        background: transparent;
        border: 0;
        margin-left: auto;
        width: calc(var(--size) - 6px);
        height: calc(var(--size) - 6px);

        &:hover,
        &:focus {
            outline: 1;
        }
    }

    &:focus-within {
        width: 20vw;

        .search-bar__submit {
            background: $mainblue;
            fill: #ffffff;
        }

        .search-bar__input {
            opacity: 1;
        }
    }

}


// number input remove arrow
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// End of Wizard Search Bar