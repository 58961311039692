// Implementing the general styling
#root{
  height: 100%;
}
:root{
  height: 100%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: initial;
}

ul{
    list-style: none !important;
    margin: 0 !important; 
}

body{
  font-family: 'Montserrat' !important;  
  background: #F7F7F7 !important;
  height: 100%;
}

.btn{
    cursor: pointer !important;
    &:focus{
      box-shadow: none !important;
    }
}

input:focus{
    box-shadow: none !important;
}

a,a:hover{
  text-decoration: none !important;
}

.bg_primary{
  background-color: $mainblue;
}