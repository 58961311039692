@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// colors
$mainblue: #0BADD7;
$lightblue: #E2EDFE;
$darkblue: #0E699C;
$secondary: #EBEBEB;
$secondaryblue:#E7F7FB;
$border: #B6B6B6;
$white: #ffffff;
$black: #000000;
$transparent: transparent;
$fontColor: #333333;
$fontSecondary: #757575;
$font_title: #393939;
$light_green:#72BD41;
$api_scripting_grey: #EDEDED;

// font sizes
$mainHeading: 1rem;
$medium_content:.875rem;
$medium_small:.813rem;
$smaller_font:.75rem;

// font weights
$semi_bold:600;
$medium:500;
$normal:400;
$light:300;

// box shadow
$shadow_title_block: 0px 0px 3px #00000033;

//block stylings
$radius_normal: 4px;
$radius_rounded: 50px;