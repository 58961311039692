.op_side_menu{
    .sidetoggle{
        position:absolute;
        right: 0;
        top: 6rem;
        .toggle-sidebar-btn {
            font-size: 14px;
            font-weight: 900;
            cursor: pointer;
            color: $mainblue;
        }
        .toggle-sidebar-open{
          cursor: pointer;
          position: absolute;
          right: -17px;
          background: $white;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 4px #00000029;
          border: 1px solid #E3E3E3;
          border-radius: 50px;
          fill: #0e699c !important;
            img{
                width:15px
            }
        }
          .toggle-sidebar-close{
              cursor: pointer;
              img{
                  width:110px
              }
          }
    }
    .sidenav{
        .nav-link{
            padding: 0.6rem 0.7rem;
            margin: 5px 0;
            border-radius: 4px;
            line-height: 0;
            .icon_title{
                svg {
                    width: 22px;
                    height: 22px;
                    fill: #646464;
                }
            }
            .title {
                padding-left: 5px;
                color: #012970;
                font-size: 12px;
                font-weight: 600;
                white-space: nowrap;
            }
        }
        .nav-link.active {
            background-color: #efefef;
            .icon_title{
                svg {
                    fill: #0e699c;
                }
            }
            .title {
                color: #0e699c;
            }
        }
        .nav-link:hover {
            background-color: #efefef;
            .icon_title{
                svg {
                    fill: #0e699c;
                }
            }
            .title {
                color: #0e699c;
            }
        }
    }
}
.drawerOpen{
    .sidenav{
        margin-top: 2vh;
        flex-direction: column !important;
        width: calc(14vw - 1rem);
        margin-left: auto;
        margin-right: auto;        
    }
    .nav-link {
        width: 100%;
    }
    .arrow-icon{
        float: right;
    }    
}
.drawerClose{
    .title{
        display: none;
    }    
    .sidenav{
        margin-top: 2vh;
        flex-direction: column !important;
        width: calc(70px - 25px);
        margin-left: auto;
        margin-right: auto;        
    }    
    .nav-link {
        width: auto;

    } 
    .collapse{
        position: absolute;
        left:70px;
        background: #ffff;
        top: 1.125rem;
        text-align: left; 
        ul{
            padding: 0px;
            li{
                padding: 0;
                display: flex;
                align-items: center;
                background: #fff;
                padding-left: 20px;
                .title{
                    position: relative !important;
                    left: 0px !important;
                    text-align: left;
                    padding-top: 10px !important;
                    padding-bottom: 10px !important;
                  }                
            }            
        }          
        &:not(.show) {
            display: block;
        }     
    } 
    .arrow-icon{
        display: none;
    }       
}

