$desktop_small: 1024px;
$desktop_medium: 1299px;
$tablet: 900px;
$mobile: 600px;

.CP_public_home {
    .hero_section {
        min-height: 550px;
        background-color: #0E699C;
        background-image: url(../../assets/images/public/bg_mask.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left;
        display: grid;

        .hero_section_wrapper {
            display: flex;
            align-items: center;
            padding: 2rem 4rem;

            @media only screen and (max-width: $tablet) {
                flex-direction: column-reverse;
                padding: 1rem;
            }

            .hero_left {
                width: 50%;

                @media only screen and (max-width: $tablet) {
                    width: 100%;
                }

                h1 {
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 1.4;
                    margin: 1rem 0;
                }

                p {
                    font-size: 19px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 1.5;
                    margin: 2.5rem 0;
                }

                .btn_white {
                    color: #0E699C;
                    background-color: #ffffff;
                    border: 1px solid #0E699C;
                    font-size: .875rem;
                    font-weight: 500;
                    width: 210px;
                    height: 40px;
                    transition: all cubic-bezier(0.075, 0.82, 0.165, 1);

                    &:hover {
                        background-color: #0E699C;
                        color: #ffffff;
                        border: 1px solid #ffffff;
                    }
                }
            }

            .hero_right {
                width: 50%;
                .hero_right_image{
                    img{
                        animation: vertical-shaking 7s ease-in-out infinite alternate-reverse both;
                    }
                }
                @keyframes vertical-shaking {
                    0% { transform: translateY(0) }
                    25% { transform: translateY(8px) }
                    50% { transform: translateY(-8px) }
                    75% { transform: translateY(8px) }
                    100% { transform: translateY(0) }
                   }
                @media only screen and (max-width: $tablet) {
                    width: 100%;
                }
            }
        }
    }

    .body_wrapper {
        padding: 2rem 0;

        .layer_image {
            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .layer_content {
            h1 {
                color: #199AD7;
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 2rem;
            }

            p {
                font-size: 14px;
                color: #333333;
                font-weight: 400;
                line-height: 1.8;
            }
        }

        ul.list {
            list-style-type: disc !important;

            li {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
                margin: .5rem 0;
                position: relative;

                &::before {
                    background: #D1D1D1;
                    border-radius: 50%;
                    content: "";
                    height: 0.6rem;
                    left: -18px;
                    position: absolute;
                    width: 0.6rem;
                    top: 5px;
                }
            }
        }

        .security_item {
            margin: 0 1rem;

            h3 {
                color: #333333;
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: .875rem;
            }

            p {
                font-size: .875rem;
                color: #333333;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .functionality_layer {
            min-height: 367px;
            padding: 2rem 0;
            background: transparent linear-gradient(107deg, #1CADD7 0%, #0E699C 100%) 0% 0% no-repeat padding-box;

            h1 {
                font-size: 32px;
                color: #ffffff;
                font-weight: 500;
                margin-bottom: 1.5rem;
            }

            .functionality_row {
                display: flex;
                justify-content: center;
                margin: 0 1rem;
                gap: 4rem;
                flex-wrap: wrap;

                @media only screen and (max-width: $tablet) {
                    justify-content: initial;
                    gap: 2rem;

                }

                .fun_card {
                    color: #FFFFFF;
                    border-radius: 4px;
                    padding: 1rem;
                    text-align: center;
                    min-height: 275px;
                    width: 275px;

                    @media only screen and (max-width: $tablet) {
                        width: 350px;
                    }

                    @media only screen and (max-width: $mobile) {
                        width: auto;
                    }

                    .card_icon {
                        width: 90px;
                        height: 90px;
                        background-color: #EBEBEB;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h3 {
                        color: #199AD7;
                        font-size: 1rem;
                        font-weight: 600;
                        margin: 1rem 0;
                    }

                    p {
                        color: #333333;
                        font-size: .875rem;
                        font-weight: 400;
                        line-height: 1.5;
                        height: 84px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                    }
                }
            }
        }

        .customers_sec {
            padding: 2rem 0;

            .client_listing {
                display: flex;
                justify-content: center;
                .client_logo {
                    width: 250px;
                    height: 80px;

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}