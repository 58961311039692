.api_script_layout{
    display: flex;
    height: 78vh;
    .api_script_layout_left_side{
        background: #EDEDED;
        width: 25%;
        height: 100%;
        position: relative;
        &_btn_block{
            background-color: #ffffff;
            padding: 0.5rem;                   
            &_btn{
                font-size: 12px;
                font-weight: 500;
                color: #1CACD7;
                border: 1px solid #1CACD7;
                border-radius: 4px;
            }
        }
        .op_accordion {
            height: calc(100% - 44px);
            overflow-y: auto;
            &::-webkit-scrollbar{
                width: 2px !important;
                &-track{
                    background-color: transparent !important;
                }
            }

            &_item{
                cursor: pointer;
            }
            &_title {
                cursor: pointer;
                background-color: #DDDDDD;
                color: #333333;
                font-size: 13px;
                white-space: nowrap;
                font-weight: 500;
                padding: 0.5rem 2rem 0.5rem 0.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &_body{
                font-size: .7rem;
                font-weight: 500;
                color: #333333;
                padding: .5rem .5rem .5rem 2.5rem ;
                &_list_panel{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: .3rem 0;
                    position: relative;
                    &_dropdown{
                        color: #707070;
                        position: absolute;
                        right: 0px;  
                        .menu-dropdown{
                            background: transparent;
                            color: #333333;
                            padding: 0;
                            border:transparent;
                            &.dropdown-toggle{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                        .dropdown-item{
                            padding: 0.25rem 0.5rem;
                            color: #333333 !important;
                            &:focus{
                                background-color: transparent !important;
                            }
                        }
                        .dropdown-menu{
                            min-width: 7rem;
                            transform: translate3d(2.333333px, 20.3333px, 0px) !important;
                            font-size: 0.7rem;
                            padding: 0;                                     
                        }
                        .show{
                            .menu-dropdown{
                                &.dropdown-toggle{
                                    background: transparent;
                                    border: transparent;
                                    color: #333333;
                                    &:after{
                                        display: none;
                                    }
                                }
                            }
                        }                        
                    }                    
                }
                &_item{
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            } 
            .api_dropdown_block{
                position: absolute;
                top: 5px;
                right: 10px;  
                .menu-dropdown{
                    background: transparent;
                    color: #333333;
                    padding: 0;
                    border:transparent;
                    &.dropdown-toggle{
                        &:after{
                            display: none;
                        }
                    }
                }
                .dropdown-item{
                    padding: 0.25rem 0.5rem;
                    color: #333333 !important;
                    &:focus{
                        background-color: transparent !important;
                    }
                }
                .dropdown-menu{
                    min-width: 7rem;
                    transform: translate3d(9.333333px, 26.3333px, 0px) !important;
                    font-size: .7rem;
                    padding: 0;                                       
                }
                .show{
                    .menu-dropdown{
                        &.dropdown-toggle{
                            background: transparent;
                            border: transparent;
                            color: #333333;
                            &:after{
                                display: none;
                            }
                        }
                    }
                }                            
            }          
          }
    }
    .test_btn_togg_panel{
        background: #F7F9FB;
        border: 1px solid #D9D9D9;
        height: 100%;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        z-index: 2;
        &_label{
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            white-space: nowrap;
            text-align: right;
        }
    }
    .api_script_layout_right_side{
        background: #F7F9FB;
        border: 1px solid #D9D9D9;
        height: 100%;
        width: 75%;
        overflow-y: auto;
        .btn_outline_api_script{
            border: 1px solid #1CACD7;
            border-radius: 4px;
            font-size: .75rem;
            font-weight: 500; 
            color: #1CACD7;           
        }
        .method_select{
            font-size: 13px;
            font-weight: 500;
            color: #333333; 
            border: 1px solid #B6B6B6;   
            border-radius: 4px;        
        }
        .request_tab_container{
            .nav{
                gap: 1rem;
                border-bottom: 1px solid #EAEAEA;
                .nav-link {
                    color: #828282;
                    font-size: 0.75rem;
                    font-weight: 500;
                    cursor: pointer !important;
                    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
                    margin: 0 1rem;
                    margin-bottom: -1px;
                    &:hover{
                        color: #1CACD7;
                    }
                    &.active{
                        color: #1CACD7;
                        font-weight: 600;
                        border-bottom: 2px solid;
                    }
                }
            }
        }
        .response_tab_container{
            .response_btn_link{
                &_dark{
                    color: #828282;
                    font-weight: 500;
                    font-size: 12px;
                }
                &_primary{
                    color: #1CACD7;
                    font-weight: 600;
                    font-size: 12px;
                }
            }
            .nav{
                gap: 1rem;
                border-bottom: 1px solid #EAEAEA;
                justify-content: space-between;
                .nav-link {
                    color: #828282;
                    font-size: 12px;
                    font-weight: 500;
                    cursor: pointer !important;
                    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
                    margin: 0 1rem;
                    margin-bottom: -1px;
                    &:hover{
                        color: #1CACD7;
                    }
                    &.active{
                        color: #1CACD7;
                        font-weight: 600;
                        border-bottom: 2px solid;
                    }
                }
            }
            .btn-group{
                .btn_group_btn{
                    background-color: #E7F7FB;
                    font-size: 12px;
                    color: #828282;
                    font-weight: 500;
                    &:hover{
                        background-color: #BAE6F3;
                        color: #333333;
                    }
                    &.active{
                        background-color: #BAE6F3;
                        color: #333333;
                        font-weight: 600;
                    }
                }
            }
            .respone_block_select{
                .form-select{
                    border-radius: 4px;
                    border: 0;
                    background-color: #BAE6F3;
                    color: #333333;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        } 
        .response_empty_container{
            &_header{
                border-bottom: 1px solid #D1D1D1;
                &_title{
                    color: #333333;
                    font-size: 13px;
                    font-weight: 600;
                }
            }
            .empty_image_content{
                color: #333333;
                font-size: 13px;
                font-weight: 400;
            }
        }
        .response_body_block{
            textarea.form-control{
                font-size: 0.813rem !important;
                font-weight: 500 !important;
            }
        }       

    }
}